<template>
  <div>
    <el-dialog
      :visible.sync="dialogVisible"
      width="50%"
      top="5vh"
      :before-close="handleClose"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      append-to-body
    >
      <div id="cesiumContainer" style="position: relative"></div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="btnClose">取 消</el-button>
        <el-button type="primary" @click="handleOk">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false
    }
  },
  mounted() {
    this.initViewer()
  },
  methods: {
    async initViewer() {
      Cesium.Ion.defaultAccessToken =
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI2OWRjYTg4NS02NzQwLTQ0ZTEtOTk2Yy1lNmE4M2IyN2Q2ZjYiLCJpZCI6MTM5NTk3LCJpYXQiOjE2ODQzOTIyMjB9.141McZH2a5ZUjmG_TNySRGkglB6jdhoehnmcGn9oC2o'
      window.viewer = new window.Cesium.Viewer('cesiumContainer', {
        timeline: false, //底部时间轴
        animation: false, //左下角仪表盘
        fullscreenButton: false, //全屏按钮
        navigationHelpButton: false, //帮助按钮
        homeButton: false,
        terrainProvider: Cesium.createWorldTerrain(),
        infoBox: false,
        navigationHelpButton: false,
        creditContainer: document.createElement('div'), //左下角logo
        sceneModePicker: false,
        geocoder: false,
        baseLayerPicker: false
      })
      this.loadTianMap(3)
      let viewer = window.viewer
      let _this = this

      let tileset = window.viewer.scene.primitives.add(
        new Cesium.Cesium3DTileset({
          url: 'http://146.56.240.129:8001/hsskproject/hssk/tileset.json', //测试
          modelMatrix: Cesium.Matrix4.fromArray([1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1]),
          baseScreenSpaceError: 1024,
          // //【重要】数值加大，能让最终成像变模糊
          skipScreenSpaceErrorFactor: 0.3,
          skipLevels: 0.2,
          preferLeaves: true,
          maximumScreenSpaceError: 2 //最大的屏幕空间误差 调整精度
        })
      )
      // window.viewer.scene.globe.depthTestAgainstTerrain = true
      //==地租自转事件

      //   this.rotate(viewer, 0)
      //   this.initCamera()
      // const homeBtnViewModel = viewer.homeButton.viewModel
      // // 在 HomeButtonViewModel 的点击事件中调用清除数据方法
      // homeBtnViewModel.command.beforeExecute.addEventListener(clearData)
      let handler = new Cesium.ScreenSpaceEventHandler(viewer.scene.canvas)
      //   // 左键获取坐标
      //   handler.setInputAction(function(movement) {
      //     var ray = viewer.camera.getPickRay(movement.position)
      //     var cartesian = viewer.scene.globe.pick(ray, viewer.scene)
      //     console.log(cartesian, 'dikaer')
      //     var cartographic = Cesium.Cartographic.fromCartesian(cartesian)
      //     console.log('坐标对象', {
      //       lng: Cesium.Math.toDegrees(cartographic.longitude),
      //       lat: Cesium.Math.toDegrees(cartographic.latitude),
      //       height: cartographic.height
      //     })
      //     // 获取当前鼠标点击的位置
      //     var pick = viewer.scene.pick(movement.position)
      //     // 判断是否选中了实体
      //     if (Cesium.defined(pick) && Cesium.defined(pick.id)) {
      //       // 如果选中了实体，则执行自定义的操作
      //       console.log('你点击了实体！', pick.id.name)
      //       // _this.dialogVisible = true
      //       if (pick.id.name && pick.id.name.devices.length > 0) {
      //         let deviceInfo = pick.id.name.devices[0]
      //         _this.$refs.iframeTemplateEzuikitCesium.showDialog(deviceInfo)
      //       } else if (pick.id.name && pick.id.name.devices.length == 0) {
      //         _this.$message.info('当前站点无设备，请到“系统-设备管理”添加！')
      //       }
      //       // 在此处编写实体被点击后的操作，例如弹出信息框等等
      //     }
      //   }, Cesium.ScreenSpaceEventType.LEFT_CLICK)
    },
    // 显示弹窗
    showDialog(itemForm, index) {
      console.log('itemForm', itemForm)
      console.log('index', index)
      this.formIndex = index
      this.form = itemForm
      this.dialogVisible = true
      // this.form.address = val?.address
      // console.log(val, 'val111')
      this.$nextTick(() => {
        this.$refs.mapCon.initMap(itemForm.position, itemForm.distance)
        // this.$refs.mapCon.initMap()
      })
    },
    // 关闭弹窗
    handleClose(done) {
      this.$refs.mapCon.destroyMap()
      this.$refs['form'].resetFields()
      this.local = {}
      this.dialogVisible = false
      done()
    },
    // 按钮关闭
    btnClose() {
      this.$refs.mapCon.destroyMap()
      this.$refs['form'].resetFields()
      this.local = {}
      this.dialogVisible = false
    },
    // 选择好点位之后
    handleOk() {
      this.btnClose()
    },
    loadTianMap(type) {
      if (type == 0) {
        //矢量地图
        window.viewer.imageryLayers.addImageryProvider(
          new Cesium.WebMapTileServiceImageryProvider({
            url:
              'http://{s}.tianditu.gov.cn/vec_c/wmts?service=wmts&request=GetTile&version=1.0.0' +
              '&LAYER=vec&tileMatrixSet=c&TileMatrix={TileMatrix}&TileRow={TileRow}&TileCol={TileCol}' +
              '&style=default&format=tiles&tk=13b2f6704c077d73d7bca54018ff2e5a',
            layer: 'tdtCva',
            style: 'default',
            format: 'tiles',
            tileMatrixSetID: 'c',
            subdomains: ['t0', 't1', 't2', 't3', 't4', 't5', 't6', 't7'],
            tilingScheme: new Cesium.GeographicTilingScheme(),
            tileMatrixLabels: [
              '1',
              '2',
              '3',
              '4',
              '5',
              '6',
              '7',
              '8',
              '9',
              '10',
              '11',
              '12',
              '13',
              '14',
              '15',
              '16',
              '17',
              '18',
              '19'
            ],
            maximumLevel: 18
          })
        )
      } else if (type == 1) {
        //影像地图
        window.viewer.imageryLayers.addImageryProvider(
          new Cesium.WebMapTileServiceImageryProvider({
            url:
              'http://{s}.tianditu.gov.cn/img_c/wmts?service=wmts&request=GetTile&version=1.0.0' +
              '&LAYER=img&tileMatrixSet=c&TileMatrix={TileMatrix}&TileRow={TileRow}&TileCol={TileCol}' +
              '&style=default&format=tiles&tk=13b2f6704c077d73d7bca54018ff2e5a',
            layer: 'tdtCva',
            style: 'default',
            format: 'tiles',
            tileMatrixSetID: 'c',
            subdomains: ['t0', 't1', 't2', 't3', 't4', 't5', 't6', 't7'],
            tilingScheme: new Cesium.GeographicTilingScheme(),
            tileMatrixLabels: [
              '1',
              '2',
              '3',
              '4',
              '5',
              '6',
              '7',
              '8',
              '9',
              '10',
              '11',
              '12',
              '13',
              '14',
              '15',
              '16',
              '17',
              '18',
              '19'
            ],
            maximumLevel: 18
          })
        )
      } else if (type == 2) {
        //栅格地图
        window.viewer.imageryLayers.addImageryProvider(
          new Cesium.WebMapTileServiceImageryProvider({
            url:
              'http://{s}.tianditu.gov.cn/ter_c/wmts?service=wmts&request=GetTile&version=1.0.0' +
              '&LAYER=ter&tileMatrixSet=c&TileMatrix={TileMatrix}&TileRow={TileRow}&TileCol={TileCol}' +
              '&style=default&format=tiles&tk=13b2f6704c077d73d7bca54018ff2e5a',
            layer: 'tdtCva',
            style: 'default',
            format: 'tiles',
            tileMatrixSetID: 'c',
            subdomains: ['t0', 't1', 't2', 't3', 't4', 't5', 't6', 't7'],
            tilingScheme: new Cesium.GeographicTilingScheme(),
            tileMatrixLabels: [
              '1',
              '2',
              '3',
              '4',
              '5',
              '6',
              '7',
              '8',
              '9',
              '10',
              '11',
              '12',
              '13',
              '14',
              '15',
              '16',
              '17',
              '18',
              '19'
            ],
            maximumLevel: 18
          })
        )
      } else if (type == 3) {
        //标记地图
        window.viewer.imageryLayers.addImageryProvider(
          new Cesium.WebMapTileServiceImageryProvider({
            url:
              'http://{s}.tianditu.gov.cn/cia_c/wmts?service=wmts&request=GetTile&version=1.0.0' +
              '&LAYER=cia&tileMatrixSet=c&TileMatrix={TileMatrix}&TileRow={TileRow}&TileCol={TileCol}' +
              '&style=default&format=tiles&tk=13b2f6704c077d73d7bca54018ff2e5a',
            layer: 'tdtCva',
            style: 'default',
            format: 'tiles',
            tileMatrixSetID: 'c',
            subdomains: ['t0', 't1', 't2', 't3', 't4', 't5', 't6', 't7'],
            tilingScheme: new Cesium.GeographicTilingScheme(),
            tileMatrixLabels: [
              '1',
              '2',
              '3',
              '4',
              '5',
              '6',
              '7',
              '8',
              '9',
              '10',
              '11',
              '12',
              '13',
              '14',
              '15',
              '16',
              '17',
              '18',
              '19'
            ],
            maximumLevel: 18
          })
        )
      } else if (type == 4) {
        //标记地图
        window.viewer.imageryLayers.addImageryProvider(
          new Cesium.WebMapTileServiceImageryProvider({
            url: 'https://[ t0-t7 ].tianditu.gov.cn/mapservice/swdx?tk=13b2f6704c077d73d7bca54018ff2e5a',
            layer: 'tdtCva',
            style: 'default',
            format: 'tiles',
            tileMatrixSetID: 'c',
            subdomains: ['t0', 't1', 't2', 't3', 't4', 't5', 't6', 't7'],
            tilingScheme: new Cesium.GeographicTilingScheme(),
            tileMatrixLabels: [
              '1',
              '2',
              '3',
              '4',
              '5',
              '6',
              '7',
              '8',
              '9',
              '10',
              '11',
              '12',
              '13',
              '14',
              '15',
              '16',
              '17',
              '18',
              '19'
            ],
            maximumLevel: 18
          })
        )
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/common/scss/formDetails.scss';
::v-deep .el-dialog__headerbtn .el-dialog__close {
  font-size: 24px;
  cursor: pointer;
}
</style>
